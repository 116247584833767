@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'condensed-j';
    src: url('./assets/RobotoCondensed-Bold.ttf');
}
.j-font {
    font-family: 'condensed-j';
}

@font-face {
    font-family: 'condensed-j2';
    src: url('./assets/RobotoCondensed-SemiBold.ttf');
}
.j-font2 {
    font-family: 'condensed-j2';
}

@font-face {
    font-family: 'condensed-j3';
    src: url('./assets/RobotoCondensed-Regular.ttf');
}
.j-font3 {
    font-family: 'condensed-j3';
}

body {
    margin: 0px;
    overflow-x: hidden;
}

.bg-img {
    background-image: url(assets/background.jpg);
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
}
.bg-img2 {
    background-image: url(assets/Polygon2.png);
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: -300px -5px;
}

.bg-logo {
    background-image: url(assets/logo.png);
    height: 100%;
    width: 100%;
}

.button-main {
    display: inline-flex;
    align-items: center;
    background: transparent;
    border: #F6FB1A 0.3em solid;
    border-radius: 2.5em;
    padding-left: 5em;
    padding-right: 5em;
    padding-top: 1em;
    padding-bottom: 1em;
}

@media screen and (max-width: 600px) {
    /* Add your styles here */
    .button-small {
        display: inline-flex;
        align-items: center;
        background: transparent;
        border: #F6FB1A 0.3em solid;
        border-radius: 2em;
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 1em;
        padding-bottom: 1em;
    }
  }

.button-main:hover {
    border: rgb(243 244 246) 0.3em solid;
  }

body {
    margin: 0;
}

.bullet {
  margin-right: 8px; /* Adjust margin as needed */
  color: #333; /* Color of bullet point */
}
ul {
  padding-left: 0;
}

li {
  display: flex;
  align-items: baseline; /* Adjust alignment as needed */
  list-style-type: none;
}
